import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PricingCards from '../components/SEOAgency/Pricing'
import Footer from "../components/App/Footer"

const MembershipLevels = () => {
    return (
        <Layout>
            <div className="pt-100"></div>
            <Navbar />
            <PricingCards />
            <div className="project-start-area tasksmanager ptb-25 white-textt">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <h3>Have a Custom Need?</h3>
                            <p className="white-textt"> Have a custom need or need an enterprise solution, feel free to contact us.</p>
                        </div>

                        <div className="col-lg-1 col-md-12 desktop-only"></div>
                        <div className="col-lg-3 col-md-12">
                            <div className="project-start-content">
                                <Link to="/contact" className="default-btn">
                                    Contact Us
                                <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="membership-levels-area ptb-100">
                <div className="container">
                    <div className="membership-levels-table table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        {/* <span className="price">Features per user</span> */}
                                        <h5>Features per user</h5>
                                    </th>
                                    <th>
                                        {/* <span className="price">Free</span> */}
                                        <h5>Starter</h5>
                                    </th>
                                    {/* <th>
                                        <h5>Premium</h5>
                                    </th> */}
                                    <th>
                                        {/* <span className="price">Business</span> */}
                                        <h5>Standard</h5>
                                    </th>
                                    <th>
                                        {/* <span className="price">Office</span> */}
                                        <h5>Business</h5>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Users
                                    </td>
                                    <td>Up to 5</td>
                                    {/* <td>Unlimited</td> */}
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                    Monthly tasks
                                    </td>
                                    <td>20</td>
                                    {/* <td>100</td> */}
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>

                                </tr>
                                <tr>
                                    <td>
                                    Storage
                                    </td>
                                    <td>200MB</td>
                                    {/* <td>1GB</td> */}
                                    <td>5GB</td>
                                    <td>15GB</td>

                                </tr>
                                <tr>
                                    <td>
                                    Max File Size
                                    </td>
                                    <td>10MB</td>
                                    {/* <td>50MB</td> */}
                                    <td>100MB</td>
                                    <td>300MB</td>

                                </tr>
                                <tr>
                                    <td>
                                        Tafoma Talk
                                    </td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        Video Meetings
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td>5 meetings per week <br/>(45min per meeting )</td> */}
                                    <td>10 meetings per week</td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        Gantt Chart
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        Creating Teams
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        Weekly/Monthly Reports
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        Office Suite
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        Webinar for team
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>

                                <tr><td></td><td></td><td></td><td></td></tr>                              
                                <tr><td><h5>Features Coming Soon...</h5></td><td></td><td></td><td></td></tr>
                                
                                <tr>
                                    <td>
                                        Project Templates
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Get Tafoma email
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                    <td className="item-check"><i className='bx bx-check'></i></td>
                                </tr>
                                <tr>
                                    <td>
                                    Add email adresses
                                    </td>
                                    <td className="item-none"><i className='bx bx-x'></i></td>
                                    {/* <td> 1</td> */}
                                    <td> Up to 5</td>
                                    <td> Up to 10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    )
}

export default MembershipLevels