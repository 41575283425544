import React from 'react'
import { Link } from 'gatsby'
import Slider from "react-slick";
import { Component } from 'react';

export default class Pricing extends Component {
    state = { isActive: false };
    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive });
      };
    render() {
        const isActive = this.state.isActive;
        const settings = {
            dots: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: false,
            swipeToSlide: true,
            arrows: false,

            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        dots: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                    }
                },
            ]
        };
        return (

            <section className="pricing-area pt-100 pb-70 bg-f4f7fe">
                <div className="container">
                    <div className="section-title">
                        <h2>Pricing Plans</h2>
                    </div>
                    <div className="row d-flex justify-content-center text-center">
                        <h5 className="align-items-center flex-column d-flex justify-content-center">Monthly</h5>
                        <label className="align-items-center flex-column d-flex justify-content-center switch">
                            <input type="checkbox" onClick={this.handleToggle} defaultChecked />
                            <span className="slider round"></span>
                        </label>
                        <h5 className="align-items-center flex-column d-flex justify-content-center">Annualy</h5>
                    </div>
                        <Slider {...settings}>
                            <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Starter</h3>
                                    </div>

                                    <div className="price">
                                        <sup>€</sup> 0.00 <sub>/ month </sub>
                                        <sub><br />‎‎‏‏‎ ‎</sub>
                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> Up to 5 Users</li>
                                        <li><i className='bx bxs-badge-check'></i> 200MB Storage per user</li>
                                        <li><i className='bx bxs-badge-check'></i> 20 Tasks per user</li>
                                        <li><i className='bx bxs-x-circle red'></i> Video Meetings</li>
                                        <li><i className='bx bxs-x-circle red'></i> Office Suite</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Select the Plan
                                            <span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Premium</h3>
                                    </div>

                                    <div className="price">
                                        <sup>$</sup> {isActive ? 8.04 : 6.99} <sub>/month</sub>
                                        <sub><br />  {isActive ? "Billed Monthly" : "Billed Annually"}</sub>


                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> 1GB Storage per user</li>
                                        <li><i className='bx bxs-badge-check'></i> 100 Tasks per user<span className='desktop-only'>‏‏‎&zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; ‎</span></li>
                                        <li><i className='bx bxs-badge-check'></i> Video Meetings*</li>
                                        <li><i className='bx bxs-x-circle red'></i> Team Reports</li>
                                        <li><i className='bx bxs-x-circle red'></i> Office Suite</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Select the Plan
                                            <span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}

                            <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Standard</h3>
                                    </div>

                                    <div className="price">
                                        <sup>€</sup> {isActive ? 3.99 : 3.39} <sub>/month</sub>
                                        <sub><br />  {isActive ? "Billed Monthly" : "Billed Annually"}</sub>
                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> 5GB Storage per user</li>
                                        <li><i className='bx bxs-badge-check'></i> Unlimited Tasks per user</li>
                                        <li><i className='bx bxs-badge-check'></i> Video Meetings*</li>
                                        <li><i className='bx bxs-badge-check'></i> Team Reports</li>
                                        <li><i className='bx bxs-x-circle red'></i> Office Suite</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Select the Plan
                                            <span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Business</h3>
                                    </div>

                                    <div className="price">
                                        <sup>€</sup> {isActive ? 5.99 : 4.99} <sub> /month</sub>
                                        <sub><br />  {isActive ? "Billed Monthly" : "Billed Annually"}</sub>
                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> 15GB Storage per user</li>
                                        <li><i className='bx bxs-badge-check'></i> Unlimited Tasks per user</li>
                                        <li><i className='bx bxs-badge-check'></i> Video Meetings</li>
                                        <li><i className='bx bxs-badge-check'></i> Team Reports</li>
                                        <li><i className='bx bxs-badge-check'></i> Office Suite</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Select the Plan
                                            <span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
            </section>
        );
    }
}
